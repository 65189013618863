@font-face {
    font-family: Montserrat ;
    src: url('assets/font/Montserrat-Regular.ttf');
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/font/Montserrat-Bold.ttf');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('assets/font/Montserrat-Light.ttf');
    font-weight: 300;
    font-display: swap
}

 section .heading {
    font-family: Montserrat;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black);
 }
 section#banner{
   /* background: url('assets/images/path-3.png'); 
   background-size: cover;
   background-repeat: no-repeat;
   background-color: black; */
   background-color: #fff;
   height: 100%;
 }
 .box {
    transition: box-shadow .3s;
    border-radius:10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
    
  }
  .box:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
 section#banner .banner-text {
    font-family: Montserrat;
    font-size: 47.6px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
 }
 section#banner .banner-text > .yellow-text {
    font-size: 70px;
    color: #f4ac33;
 }
 
 section#banner .banner-description {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
 }
 section#banner .main-img {
     width: 625px;
     padding-top: 100px;
     padding-bottom: 20px;
 }

section#features h6 {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
}

section#features p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
}
section#need .tile {
    width: 294px;
    height: 294px;
    border-radius: 28px;
    border: solid 0.7px #dbdbdb;
    background-color: #ffffff;
    margin: 20px;
    transition: 0.3s all ease-in-out;
    box-shadow: 0 7px 35px 0 rgba(7, 42, 68, 0.1);
}
section#need .tile:hover {
    box-shadow: none;
}



section#need .tile h6 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1f28;
}

section#need .tile p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a1a4ac;
}

section#downloadApp {
    background-color: black;
}

section#downloadApp h3 {
    font-family: Montserrat;
    font-size: 45px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

section#downloadApp p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

section#footer {
    background-color: black;
}

section#footer .footer-bottom{
    border-top: solid 1px #fff;
    padding-top: 30px;
}

section#footer .footer-bottom > p {
    font-family: Montserrat;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.27px;
  color: #ffffff;
}


@media (max-width: 1200px) { 
    section#banner .main-img {
        width: 100%;
    }
    section#banner .banner-text {
        padding-top: 100px;
        font-size: 32px;
     }
     section .heading {
         font-size: 35px;
     }
     section#downloadApp h3 {
         font-size: 35px;
     }
     section#features h6 {
         font-size: 25px;
     }
     .navbar-nav {
      display: none !important;
    }
 }
